<template>
    <h1 v-if="currentGuide">{{ currentGuide.title }} Guide</h1>

    <div v-html="guideContent" class="guide"></div>
</template>

<script>
export default {
    name: 'Guide',
    components: {
    },
    props: ['id', 'category'],
    computed: {
        currentGuide() {
            return this.guidesContent
                .find(item => item.category.toLowerCase() === this.category).guides
                .find(item => item.to === this.id)
        },
        guidesContent() {
            return this.$i18n.messages.guides.guides
        },
        guideContent() {
            return this.$i18n.messages[this.id]
        },
    },
    mounted() {

    },
}

</script>

<style lang="scss">
.guide {
    ul {
        margin-bottom: 12px;
        padding: 0;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    li {
        list-style-type: disc;
        margin-left: 16px;
    }

    img {
        width: 100%;
        margin-bottom: 12px;
        border-radius: 6px;
    }
}
</style>
